import React from 'react';
import './search-top-panel.css';
import { Tooltip, ExpansionPanel } from '@material-ui/core';
import { Loader } from '../common/loader/loader';

interface SearchTopPanelProps {
  airlines: any[]
  waitingState: boolean;
  toggleAirlineSelection: any;
  changeAirlineSelections: any;
}

const SearchTopPanel : React.FC<SearchTopPanelProps> = (props) => {

  let airportCols = props.airlines && props.airlines.length ?
    props.airlines.map((airline: any, i: number) => {
      return (
        <div key={'airline' + i} className="toggleImageButton" onClick={() => props.toggleAirlineSelection(airline.Id)}>
          <div className={airline.selected ? '' : 'disabled'}>
            <Tooltip title={
                <>
                  <div className="font-weight-bold text-center">{airline.Name}</div>
                  <div className="bg-white">
                    <img src={airline.ImageUrl} alt="" />
                  </div>
                </>
              }>
              <img src={airline.ImageUrl} alt=""></img>
            </Tooltip>
          </div>
        </div>
      )
    })
    : <></>;

    return (
      <ExpansionPanel defaultExpanded={true} className="bg-light">
        <Loader active={props.waitingState}>
          <div className="px-4 py-2">
            <div className="airlines-column" data-toggle="buttons">
              {airportCols}
            </div>
            <div className="buttons-column">
              <div className={"btn btn-secondary text-white smallest p-1 " + (props.airlines.length ? "" : "disabled")} onClick={() => props.changeAirlineSelections(true)}>
                Select All
              </div>
              <div className={"btn btn-secondary text-white smallest p-1 " + (props.airlines.length ? "" : "disabled")} onClick={() => props.changeAirlineSelections(false)}>
                Unselect All
              </div>
            </div>
          </div>
        </Loader>
      </ExpansionPanel>
    );
}

export default SearchTopPanel;