import SearchResultActions from "."
import { ISearchResult } from "./search-result.reducer";

export const initSearchResult = () => ({
    type: SearchResultActions.INITIALIZE_RESULT,
    payload: null
});

export const setSearchResult = (data: ISearchResult) => ({
    type: SearchResultActions.SET_SEARCH_RESULT,
    payload: data
});

export const appendJourneys = (data: ISearchResult) => ({
    type: SearchResultActions.APPEND_JOURNEYS,
    payload: data
});