import transformResult from "./dataTransformations";

const headers = {
    "x-rapidapi-host": "tripadvisor16.p.rapidapi.com",
    "x-rapidapi-key": "9665720acbmshbb853d2dd12a5fcp1542b1jsndfb367f3326c"
};

export default class ApiService {
    static getAirports(value: string): any {
        if(value) {
            return fetch("https://tripadvisor16.p.rapidapi.com/api/v1/flights/searchAirport?query=" + value, {
                "method": "GET",
                headers: headers
            }).then(result => {
                return result.json().then((list: any[]) => 
                    list && list.length ? list.map((x: any) => Object.assign(x, {
                        airport_name: x.name + ', ' + x.city_name + ' (' + x.code + ')'
                    })) : null
                )
            });
        }
        else
            return new Promise(() => {});
    }

    static getAirlines(): any {
        return fetch("https://api.skypicker.com/airlines", {
            method: "GET"
        }).then(result => {
            return result.json();
        });
    }

    static async getResult(searchKey: string): Promise<any> {
        let params = {
            currency: "AUD",
            n: 10,
            so: "PRICE",
            ns: "NON_STOP%2CONE_STOP%2CTWO_PLUS☻",
            o: "0",
            sid: searchKey
        };

        let data = this.getQueryParamString(params);

        return new Promise((resolve, reject) => 
            setTimeout(() => {
                return fetch("https://tripadvisor1.p.rapidapi.com/flights/poll" + data, {
                    method: "GET",
                    headers,
                })
                .then(result => {
                    return result.json().then(result => {
                        if(result.itineraries)
                            resolve(transformResult(result));
                        else
                            reject({});
                    });
                })
            }, 1000)
        );
    }

    static confirmFlight(params: any): any {
        let queryParams = 'v=2&partner=picky_us'

        Object.entries(params).forEach(p => {
            queryParams += '&' + p[0] + '=' + p[1];
        });
        
        return fetch("https://booking-api.skypicker.com/api/v0.1/check_flights?" + queryParams, {
            "method": "GET"
        })
        .then(result => {
            return result.json();
        });
    }

    static getQueryParamString(params: any) {
        let data = '';
        Object.entries(params).forEach((p, i) => {
            data += (i === 0 ? '?' : '&') + p[0] + '=' + p[1];
        });
        return data;
    }
}