import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Search from '../search/search';
import { connect } from 'react-redux';

const Home: React.FC = () => {
  return (
    <Search></Search>
  );
}

export default Home;