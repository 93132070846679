import React from 'react';
import './deal.css';

interface DealProps {
    deals: any[],
}

const Deal : React.FC<DealProps> = (props) => {
    let agent: any;

    console.log(props)

    return (
        <div className="dealContainer">
            <div className="dealAgent">
                <div className="dealHeading">Flight Deals</div>
                {
                    props.deals.map((deal: any, d: number) => {
                        return (
                            <div key={"agent_" + d} className="row py-1">
                                {/* <img className="image" style={{width: '50px'}} src={deal.agents[0].icon} alt="" /> */}
                                <div className="col-8 dealField text-align-left">{deal.s}</div>
                                <div className="col-4 dealPrice">{deal.pr ? deal.pr.dp : 0}</div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default Deal;