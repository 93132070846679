import SearchActionTypes from '.';
import { string } from 'prop-types';

export interface ISearchResult {
    unfilteredJourneys: any[],
    filteredJourneys: any[],
    journeys: any[],
    airlines: any[],
    agents: any[]
}

const INITIAL_RESULT: ISearchResult = {
    unfilteredJourneys: [],
    filteredJourneys: [],
    journeys: [],
    airlines: [],
    agents: []
}


export const searchResultReducer = (state = INITIAL_RESULT, action: any) => {
    switch(action.type) {
        case SearchActionTypes.INITIALIZE_RESULT:
            return {
                ...INITIAL_RESULT
            };
        case SearchActionTypes.SET_SEARCH_RESULT:
            return {
                ...state,
                ...(action.payload)
            };
        case SearchActionTypes.APPEND_JOURNEYS:
            return {
                ...state,
                unfilteredJourneys: [...state.unfilteredJourneys, ...action.payload.unfilteredJourneys],
                filteredJourneys: [...state.filteredJourneys, ...action.payload.filteredJourneys]
            };
        default:
            return state;
    }
}