import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import plane from '../images/Plane.png'

const Header: React.FC = () => {
  return (
    <div className="container container-fluid">
      <div className="app-header">
        <span>Flight Finder </span>
        <img src={plane} alt=""/>
      </div>
    </div>
  );
}

export default Header;
