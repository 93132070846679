import React from 'react';
import './search-side-panel.css';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from '@material-ui/core/Slider';
import { connect } from 'react-redux';
import { Loader } from '../common/loader/loader';
import { Utility } from '../utility';
import { initFilters, setFilters } from '../redux/filters/filters.actions';
import { setPanelModes, setAllPanelModes } from '../redux/panels/panels.actions';

interface SearchSidePanelProps {
  prices: any[];
  currency: string;
  waitingState: boolean;
  airlines: any[];
  children?: any;
  initFilters?: any;
  setFilters?: any;
  filters?: any;
  panels?: any;
  setPanelModes?: any;
}

export class SearchSidePanel extends React.PureComponent<SearchSidePanelProps, any> {
  constructor(props: any) {
    super(props);
  }

  handleChange(event: any, values: any) {
    let obj: any = {};

    let state = Object.assign({}, this.props.filters, obj);

    if(event.target.id) {
      Object.assign(state, {
        outbound: this.props.filters.outbound,
        return: this.props.filters.return,
        stops0: this.props.filters.stops0,
        stops1: this.props.filters.stops1,
        stops2: this.props.filters.stops2
      });

      obj[event.target.id] = values;

      Object.assign(state, obj);
      //this.applyFilter(2, state);
      console.log(state);
      this.props.setFilters(state);

      //this.props.applyFilters(state);
    }
  }

  handleSlider(event: any, values: any) {
    let obj: any = {};

    if(event.target.id) {
      let state = {
        outbound: this.props.filters.outbound,
        return: this.props.filters.return,
      };

      obj[event.target.id] = values;

      this.props.setFilters(state);

      let times = values.map((v: number) => { let ret = Math.round(v * 14.4 / 30) * 30; return ret > 1410 ? 1439 : ret });
      values = times.map((t: number) => t / 14.4);
      obj[event.target.id] = values;
      obj[event.target.id + 'Times'] = times;

      this.props.setFilters(obj);
    }
  }

  handleSlider2(event: any, values: any) {
    let obj: any = {};

    if(event.target.id) {
      obj[event.target.id] = values;

      let filters = Object.assign({}, this.props.filters, obj);

      filters = Object.assign({}, filters, {
        maxJourneyHours: filters.maxJourney,
        maxTransitHours: Math.round(filters.maxTransit / 2),
      });

      let times = values * 1;
      obj[event.target.id + 'Times'] = times;

      this.props.setFilters(filters);
      //this.props.applyFilters(filters);
    }
  }

  handleSlider3(event: any, values: any) {
    let obj: any = {};

    if(event.target.id) {
      obj[event.target.id] = values;

      let maxPriceRange = Math.round(values * 200 / 500) * 500;
      let maxPrice = maxPriceRange / 200;

      obj[event.target.id] = maxPrice;
      obj[event.target.id + 'Range'] = maxPriceRange;

      this.props.setFilters(obj);
    }
  }

  handleSlided(event: any, values: any) {
    this.handleChange(event, values);
  }

  valuetext(value:any) {
    return '864';
  }
  
  getOutboundDatesText(): string {
    return Utility.toTime(this.props.filters.outboundTimes[0], 'm') + ' - ' + Utility.toTime(this.props.filters.outboundTimes[1], 'm');
  }

  getReturnDatesText(): string {
    return Utility.toTime(this.props.filters.returnTimes[0], 'm') + ' - ' + Utility.toTime(this.props.filters.returnTimes[1], 'm');
  }

  getMaxJourneyDuration(): string {
    return Math.round(this.props.filters.maxJourney) + ' hours';
  }

  getMaxTransitTime(): string {
    return Math.round(this.props.filters.maxTransit / 2) + ' hours';
  }

  getMaxPrice(): string {
    return '$' + Math.round(this.props.filters.maxPriceRange);
  }

  resetFilters() {
    this.props.initFilters();
    //this.applyFilter(2, state);
  }

  onPanelChange(event: any, isExpanded: boolean) {
    let id = event.target.parentElement.id || event.target.parentElement.parentElement.id || event.target.parentElement.parentElement.parentElement.id;
    let n = id.substr(5, id.length - 5);
    let p = 'isPanel' + n + 'Expanded';
    console.log(n, isExpanded);
    this.props.setPanelModes({[p]: isExpanded});
  }

  render() {
    let panels = this.props.panels;

    return (
      <>
        <div className="">
          <div className={"btn btn-info text-white smallest p-1 mb-3" + (this.props.airlines.length ? "" : "disabled")} onClick={this.resetFilters.bind(this)}>
            Reset Filters
          </div>
        </div>
        
        <Loader active={this.props.waitingState}>
          <ExpansionPanel id="panel1" defaultExpanded={panels.isPanel1Expanded} className="bg-light" onChange={this.onPanelChange.bind(this)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
              <h6 className="group">Stops</h6>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails aria-controls="panel1bh-content">
              <div className="row">
                <div className="col-md-12 col-sm-4 pb-0">
                  <label className="w-100">
                      <Checkbox id="stops0" color="primary" checked={this.props.filters.stops0} className="py-0 col-2"
                                          onChange={this.handleChange.bind(this)}
                                          inputProps={{ 'aria-label': 'secondary checkbox', }} />
                      <span className="small col-10">Direct</span>
                  </label>
                  {/* <div className="offset-2 col-10 smaller font-weight-bold">{this.props.prices[0] ? Utility.formatCurrency(this.props.prices[0], this.props.currency) : 'None'}</div> */}
                </div>

                <div className="col-md-12 col-sm-4 pb-0">
                  <label className="w-100">
                      <Checkbox id="stops1" color="primary" checked={this.props.filters.stops1} className="py-0 col-2"
                                          onChange={this.handleChange.bind(this)}
                                          inputProps={{ 'aria-label': 'secondary checkbox', }} />
                      <span className="small col-10">1 stop</span>
                  </label>
                  {/* <div className="offset-2 col-10 smaller font-weight-bold">{this.props.prices[1] ? Utility.formatCurrency(this.props.prices[1], this.props.currency) : 'None'}</div> */}
                </div>

                <div className="col-md-12 col-sm-4 pb-0">
                  <label className="w-100">
                      <Checkbox id="stops2" color="primary" checked={this.props.filters.stops2} className="py-0 col-2"
                                          onChange={this.handleChange.bind(this)}
                                          inputProps={{ 'aria-label': 'secondary checkbox', }} />
                      <span className="small col-10">2+ stops</span>
                  </label>
                  {/* <div className="offset-2 col-10 smaller font-weight-bold">{this.props.prices[2] ? Utility.formatCurrency(this.props.prices[2], this.props.currency) : 'None'}</div> */}
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Loader>

        <ExpansionPanel id="panel2" defaultExpanded={panels.isPanel2Expanded} className="bg-light" onChange={this.onPanelChange.bind(this)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
          <h6 className="group">Flight Times</h6>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="container">
              <h6 className="row small font-weight-bold">Outbound</h6>
              <div className="row smaller">{this.getOutboundDatesText()}</div>
              <div  className="row px-2 pb-3">
                <Slider id='outbound'
                        value={this.props.filters.outbound}
                        step={0.00001}
                        onChange={this.handleSlider.bind(this)}
                        onChangeCommitted={this.handleSlided.bind(this)}
                        getAriaValueText={this.valuetext}
                />
              </div>

              <h6 className="row small font-weight-bold">Return</h6>
              <div className="row smaller">{this.getReturnDatesText()}</div>
              <div  className="row px-2">
                <Slider id="return"
                        value={this.props.filters.return}
                        step={0.00001}
                        onChange={this.handleSlider.bind(this)}
                        onChangeCommitted={this.handleSlided.bind(this)}
                        getAriaValueText={this.valuetext}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel id="panel3" defaultExpanded={panels.isPanel3Expanded} className="bg-light" onChange={this.onPanelChange.bind(this)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
            <h6 className="group">Flight Durations</h6>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="container">
              <h6 className="row small font-weight-bold">Journey Duration</h6>
              <div className="row smaller">{this.getMaxJourneyDuration()}</div>
              <div  className="row px-1 pb-3">
                <Slider id="maxJourney"
                        value={this.props.filters.maxJourney}
                        step={1}
                        onChange={this.handleSlider2.bind(this)}
                        onChangeCommitted={this.handleSlided.bind(this)}
                        getAriaValueText={this.valuetext}
                />
              </div>
              
              <h6 className="row">Transit Time</h6>
              <div className="row smaller">{this.getMaxTransitTime()}</div>
              <div  className="row px-1 pb-3">
                <Slider id="maxTransit"
                        value={this.props.filters.maxTransit}
                        step={1}
                        onChange={this.handleSlider2.bind(this)}
                        onChangeCommitted={this.handleSlided.bind(this)}
                        getAriaValueText={this.valuetext}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>


        <ExpansionPanel id="panel4" defaultExpanded={panels.isPanel4Expanded} className="bg-light" onChange={this.onPanelChange.bind(this)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
            <h6 className="group">Max Price</h6>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="container">
              <div className="row smaller">{this.getMaxPrice()}</div>
              <div  className="row px-1 pb-3">
                <Slider id="maxPrice"
                        value={this.props.filters.maxPrice}
                        step={0.1}
                        onChange={this.handleSlider3.bind(this)}
                        onChangeCommitted={this.handleSlided.bind(this)}
                        getAriaValueText={this.valuetext}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  filters: state.filters,
  searchResult: state.searchResult,
  panels: state.panels
});

const mapDispatchToProps = (dispatch: any) => ({
  initFilters: () => dispatch(initFilters()),
  setFilters: (data: any) => dispatch(setFilters(data)),
  setPanelModes: (data: any) => dispatch(setPanelModes(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchSidePanel);