import PanelsActionTypes from "."
import { INITIAL_MODES_TRUE, INITIAL_MODES_FALSE } from "./panels.reducer";

export const setPanelModes = (data: any) => ({
    type: PanelsActionTypes.SET_PANEL_MODES,
    payload: data
});

export const setAllPanelModes = (data: boolean) => ({
    type: PanelsActionTypes.SET_PANEL_MODES,
    payload: (data ? INITIAL_MODES_TRUE : INITIAL_MODES_FALSE)
});

export const setVerticalScrollPos = (data: number) => ({
    type: PanelsActionTypes.SET_VERTICAL_SCROL_POS,
    payload: data
})