import SearchActionTypes from '.';

export interface ISearch {
    searchKey: string,
    initSearchFlag: boolean
}

const INITIAL_RESULT: ISearch = {
    searchKey: '',
    initSearchFlag: false
}

export const searchReducer = (state = INITIAL_RESULT, action: any) => {
    switch(action.type) {
        case SearchActionTypes.INITIALIZE_SEARCH:
            return {
                ...INITIAL_RESULT,
                searchKey: action.payload,
                initSearchFlag: true
            }
       default:
            return state;
    }
}