import { Utility } from "../utility";

function transformResult(result: any) {
    let data: any[] = [];
    let outboundLeg: any;
    let inboundLeg: any;

    let airlines = result.carriers ? result.carriers.sort((x:any, y:any) => x.n < y.n ? -1 : 1) : [];
    result.airlines = airlines.map((x:any) => Object.assign(x, { Id: x.c, Name: x.n, ImageUrl: x.l, selected: true }));

    if(result.itineraries && result.itineraries.length) {
        result.itineraries.forEach((itin: any, i: number) => {
            outboundLeg = itin.f[0];
            Object.assign(outboundLeg, getLegInfo(outboundLeg, result));
            if(itin.f[1].l) {
                inboundLeg = itin.f[1];
                Object.assign(inboundLeg, getLegInfo(inboundLeg, result));
            }

            data.push({
                PricingOptions: itin.l,
                outboundLeg,
                inboundLeg,
            });
        });
    }

    result.prices = getPrices(data);

    result.journeys = getJourneys(data, result);

    return result;
}
    
function getLegInfo(leg: any, result: any) {
    let { stops, segments } = getStopsAndSegments(leg, result);

    return {
        depart: leg.depart,//Utility.toMins(leg.depart),
        arrival: leg.arrival,//Utility.toMins(leg.arrival),
        origin: leg.origin,
        destination: leg.destination,
        journeyDuration: leg.journeyDuration,
        transitDuration: leg.transitDuration,
        stops,
        segments
    };
}

function getPrices(data: any[] = []) {
    let price0 = 0
    let price1 = 0;
    let price2 = 0;
    let stopsData: any[];

    stopsData = data.filter(x => x.outboundLeg.stops.length === 0 && x.inboundLeg.stops.length === 0);
    if(stopsData.length) price0 = 10//stopsData.map(x => x.PricingOptions[0].Price).reduce((p, c) => c < p ? c : p);

    stopsData = data.filter(x => x.outboundLeg.stops.length <= 1 && x.inboundLeg.stops.length <= 1);
    if(stopsData.length) price1 = 10//stopsData.map(x => x.PricingOptions[0].Price).reduce((p, c) => c < p ? c : p);

    stopsData = data.filter(x => x.outboundLeg.stops.length >= 2 || x.inboundLeg.stops.length >= 2);
    if(stopsData.length) price2 = 10//stopsData.map(x => x.PricingOptions[0].Price).reduce((p, c) => c < p ? c : p);

    return { price0, price1, price2 };
}

function getJourneys(data: any[], result: any): any[] {
    let journeys: any[] = [];

    if(data && data.length) {
        data.forEach((x: any) => {
            Object.assign(x.outboundLeg, getTransformedLegInfo(x.outboundLeg, result));
            Object.assign(x.inboundLeg, getTransformedLegInfo(x.inboundLeg, result));

            journeys.push({
                PricingOptions: x.PricingOptions,
                trips: [x.outboundLeg, x.inboundLeg]
            });

        });
    }
    
    return journeys;
}

function getStopsAndSegments(leg: any, result: any): any {
    let stops: any[] = [];
    let stop: any;
    let segment: any;
    let segments: any[] = [];
    
    Object.assign(leg, {
    transitDuration: 0,
    origin: leg.l[0].da,
    destination: leg.l[leg.l.length - 1].aa,
    depart: leg.l[0].dd,
    arrival: leg.l[leg.l.length - 1].ad,
    });

    leg.journeyDuration = (Number(new Date(leg.arrival)) - Number(new Date(leg.depart))) / 60000;

    leg.l.forEach((segment: any, i: number) => {
    segments.push(segment);
    segment.departFromStop = result.airports.filter((x: any) => x.c === segment.da)[0].d;
    segment.arriveAtStop = result.airports.filter((x: any) => x.c === segment.aa)[0].d;
    segment.departTime =  Utility.getTime(segment.dd);
    segment.arriveTime = Utility.getTime(segment.ad);
    segment.duration = (Number(new Date(segment.ad)) - Number(new Date(segment.dd))) / 60000;
    segment.durationTime = Utility.toHHMM(segment.duration, 'm');
    segment.departFromCity = result.airports.filter((x: any) => x.c === segment.da)[0].tz;
    
    if(i > 0) {
        segment.transitDuration = (Number(new Date(segment.dd)) - Number(new Date(segments[i - 1].ad))) / 60000;
        segment.transitTime = Utility.toHHMM(segment.transitDuration, 'm');

        stops.push(segment);

        leg.transitDuration += segment.transitDuration;
    }
    });
    
    return { stops, segments };
}

function getTransformedLegInfo(leg: any, result: any) {
    let airline = result.airlines.filter((a: any) => a.Id === leg.l[0].m)[0];

    if(airline) {
        airline.ImageUrl = result.airlines.filter((x: any) => x.Id === airline.Id)[0].ImageUrl;

        Object.assign(leg, {
            flyFrom: leg.origin,
            flyFromCity: result.airports.filter((x: any) => x.c === leg.origin)[0].d,
            flyTo: leg.destination,
            flyToCity: result.airports.filter((x: any) => x.c === leg.destination)[0].d,
            departTime: Utility.getTime(leg.depart),
            dTime: Utility.toMins(leg.depart),
            arriveTime: Utility.getTime(leg.arrival),
            aTime: Utility.toMins(leg.arrival),
            stopCities: leg.stops.map((s:any, j:number) => (j > 0 ? ', ' : '') + s.da),
            icon: airline.ImageUrl,
            airline: airline.Name,
            journeyDurationTime: Utility.toHHMM(leg.journeyDuration, 'm'),
            transitDuration: leg.stops.length ? leg.stops.map((x: any) => x.transitDuration).reduce((x:number,y:number) => x + y) : 0,
            stopsText: leg.stops.length ? (leg.stops.length + ' stop' + (leg.stops.length > 1 ? 's' : '')) : 'Direct'
        });
    }
}

export default transformResult;