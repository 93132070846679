import FiltersActionTypes from '.';

export interface IFilters {
    stops0: boolean,
    stops1: boolean,
    stops2: boolean,
    airlineSelections: any[],
    dTime: boolean,
    rTime: boolean,
    dTime_from: number,
    dTime_to: number,
    rTime_from: number,
    rTime_to: number,
    departureTimeFrom: number,
    departureTimeTo: number,
    durationFrom: number,
    durationTo: number,
    outbound: number[],
    return: number[],
    outboundTimes: number[],
    returnTimes: number[],
    panelExpanded: boolean[],
    maxJourney: number,
    maxJourneyHours: number,
    maxTransit: number,
    maxTransitHours: number,
    maxPrice: number,
    maxPriceRange: number,
    maxJourneyDuration: number,
    maxTransitDuration: number
}

const INITIAL_FILTERS: IFilters = {
    stops0: true,
    stops1: true,
    stops2: true,
    airlineSelections: [],
    dTime: false,
    rTime: false,
    dTime_from: 0,
    dTime_to: 0,
    rTime_from: 0,
    rTime_to: 0,
    departureTimeFrom: 0,
    departureTimeTo: 0,
    durationFrom: 0,
    durationTo: 0,
    outbound: [0, 100],
    return: [0, 100],
    outboundTimes: [0, 1439],
    returnTimes: [0, 1439],
    panelExpanded: [true, true],
    maxJourney: 100,
    maxJourneyHours: 100,
    maxTransit: 100,
    maxTransitHours: 50,
    maxPrice: 100,
    maxPriceRange: 20000,
    maxJourneyDuration: 0,
    maxTransitDuration: 0
}

export const filtersReducer = (state = INITIAL_FILTERS, action: any) => {
    switch(action.type) {
        case FiltersActionTypes.INITIALIZE_FILTERS:
            return {
                ...INITIAL_FILTERS,
                airlineSelections: state.airlineSelections || []
            };
        case FiltersActionTypes.SET_FILTERS:
            return {
                ...state,
                ...(action.payload)
            };
        default:
            return state;
    }
}