import React from 'react';
import SearchResult from '../search-result/search-result';
import SearchPanel from '../search-panel/search-panel';
import SearchSidePanel from '../search-side-panel/search-side-panel';
import ApiService from '../services/apiService';
import './search.css';
import SearchTopPanel from '../search-top-panel/search-top-panel';
import { connect } from 'react-redux';
import { Props } from '../common';
import { initSearchParams, setSearchParams } from '../redux/search-params/search-params.actions';
import { initSearchResult, setSearchResult, appendJourneys } from '../redux/search-result/search-result.actions';
import { setSearchWaitingState } from '../redux/search-waiting-state/search-waiting-state.actions';
import { ISearchParams } from '../redux/search-params/search-params.reducer';
import { ISearchResult} from '../redux/search-result/search-result.reducer';
import { ISearchWaitingState } from '../redux/search-waiting-state/search-waiting-state.reducer';
import { IFilters } from '../redux/filters/filters.reducer';
import { setFilters, initFilters } from '../redux/filters/filters.actions';
import { initSearch } from '../redux/search/search.actions';
import { withRouter } from 'react-router-dom';
import { setAllPanelModes, setVerticalScrollPos } from '../redux/panels/panels.actions';

interface SearchProps extends Props {
  search?: any;
  searchParams?: ISearchParams;
  searchResult?: ISearchResult;
  filters?: IFilters;
  searchWaitingState?: ISearchWaitingState;
  initSearch: any;
  initSearchResult?: any;
  initSearchParams?: any;
  setSearchResult?: any;
  appendJourneys?: any;
  setSearchParams?: any;
  setSearchWaitingState?: any;
  initFilters?: any;
  setFilters?: any;
  children?: any;
  panels?: any,
  setAllPanelModes?: any,
  setVerticlScrollPos: any,
  noRecords: boolean
}

class Search extends React.Component<SearchProps, any> {
  filteredJourneys = [];
  timeout: any;
  appliedFilters: any = {};
  isLoading = false;

    // ApiService.getAirlines()
    //   .then((data: any) => {
    //     this.setState(Object.assign({}, this.state, { airlines: data }));
    //     this.airlines = data;
        // this.onSearchClicked();
      // });
  constructor(props: SearchProps) {
    super(props);

    if(window.innerWidth < 768 && !props.panels.isInitialized)
      props.setAllPanelModes(false);
  }

  onFlightSelected(i: number) {
    let params = {
      booking_token: this.props.searchResult ? this.props.searchResult.journeys[i].bookingToken : '',
      bnum: 1,
      currency: this.props.searchParams ? this.props.searchParams.currency : ''
    };

    ApiService.confirmFlight(params)
      .then((result: any) => {
        console.log(result);
      });
  }

  setSearchParams(searchParams: any) {
    setTimeout(() =>
      this.props.setSearchParams(searchParams)
    , 100);
  }

  onSearchClicked() {
    let criteria = Object.assign({}, this.props.searchParams);

    if (!criteria) return;
    
    let params = {
      currency: "AUD",
      ta: 1,
      c: 0, //criteria.cabinClass,
      d1: criteria.arrivalAirportCode,
      o1: criteria.departureAirportCode,
      dd1: criteria.departureDate
      // outboundDate: criteria.departureDate,
      // cabinClass: criteria.cabinClass,
      // children: "0",
      // infants: "0",
      // country: "AU",
      // currency: "AUD",
      // locale: "en-US",
      // originPlace: criteria.departureAirportCode,
      // destinationPlace: criteria.arrivalAirportCode,
    };

    if(criteria.returnDate) {
      Object.assign(params, {
        d2: criteria.departureAirportCode,
        o2: criteria.arrivalAirportCode,
        dd2: criteria.returnDate
      });
    }

    this.props.setSearchWaitingState({
      isSearching: true,
      waitingState1: true,
      waitingState2: true,
      waitingState3: true
    });
  }

  tryToLoadMore() {
    if(this.props.searchWaitingState
      && !this.props.searchWaitingState.isSearching
      && !this.isLoading
      && this.props.searchWaitingState.hasMoreToLoad
      && this.props.search.searchKey) {
        this.loadMore(this.props.search.searchKey)
      }
  }

  loadMore(searchKey: string) {
    console.log(this.props.searchWaitingState, this.isLoading);
    
    this.isLoading = true;

    ApiService.getResult(searchKey)
      .then((result: any) => {
        console.log(result);

        if(result.errors && result.errors.length) {
          let error = result.errors[0].message;
          throw(error);
        }

        if(result.summary.c) console.log('No more records to load');

        this.props.setSearchWaitingState({
          hasMoreToLoad: !result.summary.c
        });

        this.props.setSearchParams({
          price0: result.prices.price0,
          price1: result.prices.price1,
          price2: result.prices.price2
        });

        this.props.setSearchResult({
          airlines: result.airlines
        });
        
        if (this.props && this.props.searchWaitingState && this.props.searchWaitingState.firstLoad) {
          this.props.setFilters({ airlineSelections: result.airlines });
          this.props.setSearchWaitingState({ firstLoad: false });
        }

        let filteredJourneys = this.getFilteredJourneys(result.journeys);

        this.props.appendJourneys({
          unfilteredJourneys: result.journeys,
          filteredJourneys
        });

        console.log('Unfiltered', result.journeys.length, 'Filtered', filteredJourneys.length);

        if(this.props.filters && this.props.filters.airlineSelections && !this.props.filters.airlineSelections.length)
          this.props.setFilters({ airlineSelections: result.airlines });

        this.isLoading = false;
      }, (error: any) => {
        this.isLoading = false;
        this.props.setSearchWaitingState({ noRecords: true });

        console.log('Error ----------------------------');
        //this.props.initSearchResult();
      })
  }

  toggleAirlineSelection(id: string) {
    let airlines = this.props.filters && this.props.filters.airlineSelections ? this.props.filters.airlineSelections : [];

    let airline = airlines.filter((a: any)=> a.Id === id)[0];
    airline.selected = !airline.selected;

    this.props.setFilters({ airlineSelections: airlines });
  }

  changeAirlineSelections(flag: boolean) {
    let airlines = this.props.filters
                    ? this.props.filters.airlineSelections.map((airline: any) => Object.assign({}, airline, { selected: flag }))
                    : [];

    this.props.setFilters({ airlineSelections: airlines });
  }

  showJourneyDetails(id: any) {
    this.props.setVerticlScrollPos(window.scrollY);
    this.props.history.push('/booking/' + id);
  }

  getFilteredJourneys(journeys: any = []): any[] {
    let filters: any = this.props.filters;
    let unfilteredJourneys = journeys.length ? journeys
                                : (this.props.searchResult ? this.props.searchResult.unfilteredJourneys : []);

    filters = Object.assign({}, filters, {
      dTime: true, dTime_from: filters.outboundTimes[0],
      dTime_to: filters.outboundTimes[1],
      rTime: true, rTime_from: filters.returnTimes[0],
      rTime_to: filters.returnTimes[1],
      stops0: filters.stops0,
      stops1: filters.stops1,
      stops2: filters.stops2,
      maxJourneyDuration: filters.maxJourneyHours * 60,
      maxTransitDuration: filters.maxTransitHours * 60,
      maxPriceRange: filters.maxPriceRange,
    });

    let outLeg: any;
    let inLeg: any;
    let selAirlines: number;

    let filteredJourneys = unfilteredJourneys.filter((x:any) => {
      outLeg = x.trips[0];
      inLeg = x.trips[1];

      const res =
        (!filters.dTime || (filters.dTime && (outLeg.dTime >= filters.dTime_from) && (outLeg.dTime <= filters.dTime_to) )) &&
        (!filters.rTime || (filters.rTime && (inLeg.dTime >= filters.rTime_from) && (inLeg.dTime <= filters.rTime_to) )) &&
        (!filters.maxJourneyDuration || (filters.maxJourneyDuration && (outLeg.journeyDuration <= filters.maxJourneyDuration) && (inLeg.journeyDuration <= filters.maxJourneyDuration))) &&
        (!filters.maxTransitDuration || (filters.maxTransitDuration && (outLeg.transitDuration <= filters.maxTransitDuration) && (inLeg.transitDuration <= filters.maxTransitDuration))) &&
        (!filters.maxPriceRange || (filters.maxPriceRange && (x.PricingOptions[0].pr.p <= filters.maxPriceRange))) &&
        (filters.airlineSelections.length && filters.airlineSelections.filter((a: any) => (a.Id === outLeg.segments[0].m) && (a.selected)).length) &&
            ((filters.stops0 || (!filters.stops0 && (inLeg.stops.length > 0 && outLeg.stops.length !== 0))) &&
              (filters.stops1 || (!filters.stops1 && (inLeg.stops.length !== 1 && outLeg.stops.length !== 1))) &&
              (filters.stops2 || (!filters.stops2 && (inLeg.stops.length !== 2 || outLeg.stops.length !== 2))));

      return res;
    });

    console.log('filteredJourneys', filteredJourneys.length);

    return filteredJourneys;
  }

  componentDidMount() {
    this.props.initSearchParams();
    console.log('searchParams-----------',this.props.searchParams);
    window.scrollBy(0, this.props.panels.verticalScrollPos);
  }

  componentDidUpdate() {
    if(this.props.filters !== this.appliedFilters) {
        if(this.props.searchWaitingState && !this.props.searchWaitingState.waitingState2) {
          this.props.setSearchWaitingState({ waitingState2: true, waitingState3: true });
        }
    
        this.appliedFilters = this.props.filters;

      //this.timeout = setTimeout(() => {
        let filteredJourneys = this.getFilteredJourneys();

        this.props.setSearchResult({
          filteredJourneys
        });

        this.props.setSearchWaitingState({ waitingState1: false, waitingState2: false, waitingState3: false });
    }
  }

  render() {
    
    return (
      <div className="container container-fluid bg-light p-4">
        <div className="search shadow-sm p-3 mb-3 rounded">
          <SearchPanel  searchClicked={this.onSearchClicked.bind(this)}>
          </SearchPanel>
        </div>

        <div className="row">
          <div className="col-12">
            <SearchTopPanel airlines={this.props.filters ? this.props.filters.airlineSelections : []}
                            waitingState={false}
                            toggleAirlineSelection={this.toggleAirlineSelection.bind(this)}
                            changeAirlineSelections={this.changeAirlineSelections.bind(this)}
                            >
            </SearchTopPanel>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3 col-sm-12 mb-3">
            <SearchSidePanel  airlines={this.props.searchResult ? this.props.searchResult.airlines : []}
                              prices={this.props.searchParams ? [this.props.searchParams.price0, this.props.searchParams.price1, this.props.searchParams.price2] : []}
                              currency={this.props.searchParams ? this.props.searchParams.currency : ''}
                              waitingState={this.props.searchWaitingState ? this.props.searchWaitingState.waitingState2 : false}
                              >
            </SearchSidePanel>
          </div>
          <div className="col-md-9 col-sm-12">
            <SearchResult journeys={this.props.searchResult ? this.props.searchResult.filteredJourneys : []}
                          airlines={this.props.searchResult ? this.props.searchResult.airlines : []}
                          onSelected={this.showJourneyDetails.bind(this)}
                          isLoading={this.isLoading}
                          loadMore={this.tryToLoadMore.bind(this)}
                          noRecords={this.props.noRecords}
                          hasMoreToLoad={this.props.searchWaitingState ? this.props.searchWaitingState.hasMoreToLoad : false}
                          waitingState={this.props.searchWaitingState ? this.props.searchWaitingState.waitingState3 : false}>
            </SearchResult>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  search: state.search,
  searchParams: state.searchParams,
  searchResult: state.searchResult,
  searchWaitingState: state.searchWaitingState,
  filters: state.filters,
  panels: state.panels,
  noRecords: state.noRecords
});

const mapDispatchToProps = (dispatch: any) => ({
  initSearch: (data: any) => dispatch(initSearch(data)),
  initSearchResult: () => dispatch(initSearchResult()),
  initSearchParams: () => dispatch(initSearchParams()),
  setSearchResult: (data: ISearchResult) => dispatch(setSearchResult(data)),
  appendJourneys: (data: ISearchResult) => dispatch(appendJourneys(data)),
  setSearchParams: (data: ISearchParams) => dispatch(setSearchParams(data)),
  initFilters: () => dispatch(initFilters()),
  setFilters: (data: ISearchParams) => dispatch(setFilters(data)),
  setSearchWaitingState: (data: ISearchWaitingState) => dispatch(setSearchWaitingState(data)),
  setAllPanelModes: (data: any) => dispatch(setAllPanelModes(data)),
  setVerticlScrollPos: (data: any) => dispatch(setVerticalScrollPos(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));