import React from 'react';
import ApiService from '../services/apiService';
import { Utility } from '../utility';
import './search-panel.css';
import { Constants } from '../services/constants';
import { connect } from 'react-redux';
import { setSearchParams } from '../redux/search-params/search-params.actions';
import { setSearchWaitingState } from '../redux/search-waiting-state/search-waiting-state.actions';

const module1 = require('throttle-debounce');
const today = Utility.formatDateTime(new Date(), '-', false, false, true);

interface SearchPanelProps {
  searchClicked: any,
  initSearchFlag?: boolean,
  children?: any
}

interface SearchPanelState {
  departureAirport: string;
  departureAirportCode: string;
  departureAirports: any[];
  departureDate: string;
  arrivalAirport: string;
  arrivalAirportCode: string;
  arrivalAirports: any[];
  returnDate: string;
  adults: number;
  cabinClass: string;
  waitingState: number;
}

class SearchPanel extends React.PureComponent<any, any> {
  debounce: any;

  componentDidMount() {
    let date1 = new Date();
    let date2 = new Date();
    date1.setDate(date1.getDate() + 1);
    date2.setDate(date2.getDate() + 7);

    this.props.setSearchWaitingState({ waitingState0: 0 });
    
    this.debounce = module1.debounce(500, this.fetchAirportList);

    if(!this.props.initSearchFlag) {
      this.onSearchClicked();
    }
  }

  onSearchClicked() {
    this.props.setSearchWaitingState({ waitingState: 1 });

    let criteria = {
      departureDate: this.props.searchParams.departureDate,
      returnDate: this.props.searchParams.returnDate,
      cabinClass: this.props.searchParams.cabinClass,
      children: this.props.searchParams.children,
      infants: this.props.searchParams.infants,
      country: this.props.searchParams.country,
      locale: this.props.searchParams.locale,
      departureAirportCode: this.props.searchParams.departureAirportCode,
      arrivalAirportCode: this.props.searchParams.arrivalAirportCode,
      adults: this.props.searchParams.adults,
    };

    this.props.searchClicked(criteria);
  }

  handleChange(event: any) {
    let obj: any = {};
    let { name, value } = event.target;
    obj[name] = value;
    this.props.setSearchParams(obj);

    if(name === 'departureAirport' || name === 'arrivalAirport') {
      let obj2 = this.findInList(name, value);
      if(obj2)
        this.props.setSearchParams(Object.assign({}, obj, obj2));
      else
        this.debounce(name, value);
    }
  }

  findInList(listName: string, value: string): any {
    let airports = this.getFromList(listName + 's');

    let ret = this.tryToFind(listName, value, airports);

    if(ret)
      return ret;

    // ApiService.getAirports(value)
    //   .then((list: any[]) => {
    //     if(list && list.length) {
    //       console.log(list);
    //       ret = this.tryToFind(listName, value, list);
    //       return ret;
    //     }
    //     else {
    //       return null;
    //     }
    //   });
  }

  tryToFind(listName: string, value: string, airports: any[]): any {
    let list = airports && airports.length ? airports.filter((x:any) => x.airport_name === value) : [];
    if(list.length) {
      let obj: any = {};
      obj[listName + 'Code'] = list[0].code;
      this.assignToList(listName + 's', []);
      return obj;
    }
    else
      return null;
  }

  fetchAirportList(listName: string, value: string) {
    ApiService.getAirports(value)
      .then((data: any) => {
        this.assignToList(listName + 's', data);
      });
  }

  getFromList(name: string): any[] {
    let listName = Object.keys(this.props.searchParams).filter(x => x === (name))[0];
    let node: any[] = (Object.entries(this.props.searchParams).filter(x => x[0] === listName)[0]);
    let list = node[1];
    return list;
  }
  
  assignToList(listName: string, list: any[]) {
    let obj:any = {};
    obj[listName] = list;
    this.props.setSearchParams(obj);
  }
  
  render() {
    let sp = this.props.searchParams;
    let departureAirports = sp.departureAirports && sp.departureAirports.length
                              ? sp.departureAirports.map((x:any, i:number) =>
                                <option key={i} value={x.airport_name} />)
                              : <></>;
    let arrivalAirports = sp.arrivalAirports && sp.arrivalAirports.length
                              ? sp.arrivalAirports.map((x:any, i:number) => <option key={i} value={x.airport_name} />)
                              : <></>;
    let cabinClasses = Constants.CabinClasses.map((x:any, i:number) => <option key={i} value={x.id}>{x.name}</option>);
    
    return (
        <>
          <div className="search-panel text-white smaller font-weight-bold">
            <div className="row">
              <div className="col-md-3 col-6 pt-3">
                  <div>From</div>
                  <input className="form-control" name="departureAirport" value={sp.departureAirport} onChange={this.handleChange.bind(this)} list="departureAirports" />
                  <datalist id="departureAirports">
                    {departureAirports}
                  </datalist>
              </div>
              <div className="col-md-3 col-6 pt-3">
                  <div>To</div>
                  <input className="form-control" name="arrivalAirport" value={sp.arrivalAirport} onChange={this.handleChange.bind(this)} list="arrivalAirports" />
                  <datalist id="arrivalAirports">
                    {arrivalAirports}
                  </datalist>
              </div>
              <div className="col-md-3 col-6 pt-3">
                  <div>Depart</div>
                  <input className="form-control" name="departureDate" type="date" value={sp.departureDate} min={today} onChange={this.handleChange.bind(this)} />
              </div>
              <div className="col-md-3 col-6 pt-3">
                  <div>Return</div>
                  <input className="form-control" name="returnDate" type="date" value={sp.returnDate} min={sp.departureDate || today} onChange={this.handleChange.bind(this)} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 col-6 pt-3">
                  <div>Passengers</div>
                  <input className="form-control" name="adults" type="number" value={sp.adults} min='0' max='2' onChange={this.handleChange.bind(this)} />
              </div>
              <div className="col-md-3 col-6 pt-3">
                  <div>Cabin Class</div>
                  <select className="form-control" name="cabinClass" value={sp.cabinClass} onChange={this.handleChange.bind(this)}>
                    {cabinClasses}
                  </select>
              </div>
              <div className="offset-md-3 col-md-3 col-sm-12 pt-4 text-right">
                <button type="button" className="btn btn-primary col-sm-12" onClick={this.onSearchClicked.bind(this)}>Search</button>
              </div>
            </div>
          </div>
        </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  initSearchFlag: state.search.initSearchFlag,
  searchParams: state.searchParams
})

const mapDispatchToProps = (dispatch: any) => ({
  setSearchParams: (payload: any) => dispatch(setSearchParams(payload)),
  setSearchWaitingState: (payload: any) => dispatch(setSearchWaitingState(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchPanel);