import React from 'react';
import './hoverModal.css'

interface HoverModalProps {
  param?: any;
  content: any;
  width: string;
}

interface HoverModalState {
  isOpen: boolean;
  modalTop: number;
  modalLeft: number;
  modalWidth: number;
  clientX: number;
  clientY: number;
  anchor: any;
  direction: string;
  target: any;
}

class HoverModal extends React.Component<HoverModalProps, HoverModalState> {
  constructor(props: any) {
    super(props);
    this.state = { isOpen: false, modalTop: 0, modalLeft: 0, modalWidth: 0, clientX: 0, clientY: 0, anchor: null, direction: 'L', target: null };
  } 

  mouseEnter(event: any) {
    this.setState({ isOpen: true });
    
    let target = event.currentTarget.children[0];
    let anchor: any = document.querySelector('#hoverModalAnchor');

    this.setState(Object.assign({}, this.state,
      {
        isOpen: true,
        target,
        clientX: event.clientX,
        clientY: event.clientY,
        anchor: anchor.firstChild
      }
    ));

    //console.log('width', target.clientWidth, 'height', target.clientHeight, 'left', target.clientLeft, 'right', target.clientRight);
    // console.log('screenX,clientX', event.screenX, event.clientX);
    // console.log('target', target.clientTop, target.clientWidth, target.clientLeft, target.clientRight);
  }

  mouseLeave(event: any) {    
    this.setState({ isOpen: false, modalTop: 0, clientY: 0, target: null });
  }

  componentDidUpdate() {
    if(this.state.modalTop === 0 && this.state.target) {
      let element: any = document.querySelector('#hoverMessageBody');
      
      if(element) {
        let modalTop = (element.offsetHeight - this.state.anchor.offsetHeight) / 2 + this.state.anchor.offsetTop;
        if((modalTop > this.state.clientY) && this.state.clientY !== 0) {
          modalTop = this.state.clientY - 10;
        }

        let modalLeft = 0 - element.clientWidth - 12;
        let direction = 'R';

        //console.log(this.state.clientX, element.offsetWidth, this.state.anchor.offsetWidth, window.innerWidth)
        //if((this.state.clientX + element.offsetWidth + this.state.anchor.offsetWidth + 40 > window.innerWidth) && this.state.clientX !== 0) {

        let modalWidth = element.offsetWidth + 12;

        if(modalWidth > this.state.clientX) {
          modalLeft = this.state.target.clientWidth + 12;
          direction = 'L';
        }
        
        // console.log('offsetHeight', element.offsetHeight, 'targetHeight', this.state.target.clientHeight,
        //             'modalTop', modalTop, 'modalLeft', modalLeft,  'clientWidth', element.clientWidth,'innerWidth', window.innerWidth);

        this.setState(Object.assign({}, this.state, { modalTop, modalLeft, modalWidth, direction, clientX: 0, clientY: 0 }));
      }
    }
  }

  render() {
    return (
      <div className="hoverModal" id={this.props.param || ''} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseLeave.bind(this)}>
        <div id="hoverModalAnchor">
          {this.props.children}
        </div>
        {this.state.isOpen &&
        <div className="background">
          <div id="hoverContainer" className="hoverContainer" style={{
            top: (0 - this.state.modalTop - this.state.anchor.offsetTop) + 'px',
            left: this.state.modalLeft + 'px',
            width: this.props.width
            // width: this.state.modalWidth + 'px'
          }}>
            {
              this.state.direction === 'L' && 
              <div className="arrowLeft" style={{ top: this.state.modalTop + this.state.anchor.offsetTop + 'px' }}>
                <div className="outer"></div>
                <div className="inner"></div>
              </div>
            }
            <div id="hoverMessageBody" className="message-body shadow">
              {this.props.content}
            </div>
            {
              this.state.direction === 'R' &&
              <div className="arrowRight" style={{ top: this.state.modalTop + 'px' }}>
                <div className="outer"></div>
                <div className="inner"></div>
              </div>
            }
          </div>
        </div>
        }
      </div>
    );
  }
}

export default HoverModal;

//https://codepen.io/ompmega/pen/HznuC