import SearchParamsActions from "."
import { ISearchParams } from "./search-params.reducer";

export const initSearchParams = () => ({
    type: SearchParamsActions.INITIALIZE_PARAMS,
    payload: null
});

export const setSearchParams = (data: ISearchParams) => ({
    type: SearchParamsActions.SET_SEARCH_PARAMS,
    payload: data
});