import React from 'react';
import './itinerary.css';
import { Utility } from '../utility';
import { connect } from 'react-redux';

interface ItineraryProps {
    trip: any,
    airlines: any[];
}

const Itinerary : React.FC<ItineraryProps> = (props) => {
    let rows: any[] = [];
    let itinDetails: any;
    let stop: any;
    
    props.trip.segments.forEach((segment: any, s: number) => {
        itinDetails = getItinDetails(segment, s, props.airlines);
        rows.push(itinDetails);
        if(s < props.trip.segments.length - 1) {
            stop = props.trip.stops[s];
            rows.push(
                <div key={'stop_' + s} className="itinStop row py-1">
                    <div className="col-12">
                        <span className="itinFieldTitle">{stop.transitTime}</span>
                        <span className="itinField"> stop at </span>
                        <span className="itinFieldTitle">{stop.departFromCity}</span>
                    </div>
                </div>
            );
        }
    });

    rows.push(
        <div className="row py-1" key={'summary_' + props.trip.Id}>
            <div className="col-12">
                <span className="itinFieldTitle">Total Flight Duration </span>
                <span className="itinHighField">{Utility.toHHMM(props.trip.journeyDuration - props.trip.transitDuration, 'm')}</span>
            </div>
            <div className="col-12">
                <span className="itinFieldTitle">Transit Duration </span>
                <span className="itinHighField">{Utility.toHHMM(props.trip.transitDuration, 'm')}</span>
            </div>
            <div className="col-12">
                <span className="itinFieldTitle">Total Duration </span>
                <span className="itinHighField">{Utility.toHHMM(props.trip.journeyDuration, 'm')}</span>
            </div>
        </div>
    );

    return <div key={'trip_' + props.trip.Id} className="container itinContainer px-3">{rows}</div>;
}

const getItinDetails = (stop: any, s: number, airlines: any[]) => {
    let airline: any;
    let flightNo: string;
    let departTime: string;
    let arriveTime: string;

    airline = airlines.filter((airline :any) => airline.Id === stop.m)[0];
    flightNo = airline ? airline.c + stop.e : '';
    // departTime = Utility.formatDateTimeDay(new Date(stop.DepartureDateTime));
    // arriveTime = Utility.formatDateTimeDay(new Date(stop.ArrivalDateTime));

    if(airline)
        return (
            <div key={'segment_' + s} className="row itinAirline">
                <div className="col-12 itinAirlineTitle">
                    <div className="">{airline.Name} - Flight # {flightNo}</div>
                </div>

                <div className="col-3 itinFieldTitle">Departs</div>
                <div className="col-9 itinField">{stop.departFromStop}</div>
                <div className="offset-3 col-9 itinTime">{stop.departTime}</div>

                <div className="col-3 itinFieldTitle">Arrives</div>
                <div className="col-9 itinField">{stop.arriveAtStop}</div>
                <div className="offset-3 col-9 itinTime">{stop.arriveTime}</div>

                <div className="col-3 itinFieldTitle">Duration</div>
                <div className="col-9 itinTime">{Utility.toHHMM(stop.duration, 'm')}</div>
            </div>
        );
}

const mapStateToProps = (state: any) => ({
    airlines: state.searchResult.airlines,
    filteredJourneys: state.searchResult.filteredJourneys
});

export default connect(mapStateToProps)(Itinerary);