import React from 'react';
import { Props } from '../common';
import { connect } from 'react-redux';
import Itinerary from '../itinerary/itinerary';
import './booking.css';

interface BookingProps extends Props {
    id: string,
    filteredJourneys: any[]
}

const Booking = (props: BookingProps) => {
    const onReturn = () => {
        props.history.push('/');
    }

    let id = props.match.params.id;

    let journey: any = props.filteredJourneys[id];

    window.scrollTo(0, 0);

    return (
        <div className="container-fluid">
            <div className="row m-1">
                { journey && journey.trips.map((trip: any, t: number) => {
                    return (
                        <div key={t} className="col-sm-12 col-md-6">
                            <div className="border border-success rounded-lg m-2">
                                <div className="h5 bg-success text-white border border-dark p-1 text-center">{t === 0 ? 'Outbound' : 'Inbound'}</div>
                                <Itinerary trip={trip}></Itinerary>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
            <div className="row m-1">
                <div className="offset-md-9 col-md-3 col-sm-12 pb-3 text-right">
                    <button type="button" className="btn btn-primary col-sm-12" onClick={onReturn}>Return</button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    filteredJourneys: state.searchResult.filteredJourneys
});

export default connect(mapStateToProps)(Booking);