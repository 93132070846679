import React from 'react';
import './anim-loader.css';

interface AnimLoaderProps {
  text: string;
  active: boolean;
}

export const AnimatedLoader : React.FC<AnimLoaderProps> = (props) => {
    return (
      <div className="animated text-center" key={0} style={{display: props.active ? '' :'none'}}>
        {props.text}
      </div>

    );
}