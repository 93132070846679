import React from 'react';
import './search-result.css';
import { Loader } from '../common/loader/loader';
import { Tooltip } from '@material-ui/core';
import Itinerary from '../itinerary/itinerary';
import HoverModal from '../common/hoverModal/hoverModal';
import Deal from '../deals/deal';
import { Props } from '../common';
import { AnimatedLoader } from '../common/anim-loader/anim-loader';
const InfiniteScroll = require('react-infinite-scroller');

interface SearchProps extends Props {
    journeys: any,
    airlines: any[],
    waitingState: boolean;
    onSelected: any;
    isLoading: boolean;
    loadMore: any;
    hasMoreToLoad: boolean;
    noRecords: boolean;
}

const SearchResult : React.FC<SearchProps> = (props) => {
    let rows: any[] = [];
    let tripRows: any[];

    if(props.journeys) {
        props.journeys.forEach((journey: any, j: number) => {
            tripRows = [];
            journey.trips.forEach((trip: any, t: number) => {
                tripRows.push(
                    <div className="col-md-6 col-sm-12 border-left border-bottom py-3" key={j+'-'+t}>
                        <div className="container">
                            <div className="row">
                                <div className="col-3">
                                    <HoverModal param={j.toString()}
                                                key={'modal_' + j+'-'+t}
                                                width="300px"
                                                content={<Itinerary trip={trip}></Itinerary>}>
                                        <img className="image" style={{width: '50px'}} src={trip.icon} alt="" />
                                    </HoverModal>
                                </div>
                                <div className="col-3 text-right">
                                    <div className="h5">{trip.departTime}</div>
                                    <Tooltip title={trip.flyFromCity}>
                                        <div className="small">{trip.flyFrom}</div>
                                    </Tooltip>
                                </div>
                                <div className="col-3 stopsContainer small">
                                    <span className="small">{trip.journeyDurationTime}</span>
                                    <ul className="stopLine">
                                        {
                                            trip.stops.map((stop:any, s:number) =>
                                                <Tooltip title={stop.departFromCity + ' (' + stop.transitTime + ' stop)'} key={j+'-'+t+'-'+s}>
                                                    <li className="stopDot"></li>
                                                </Tooltip>
                                            )
                                        }
                                    </ul>
                                    <span className={'small ' + (trip.stops.length ? 'text-danger' : 'text-success')}>{trip.stopsText}</span>
                                </div>
                                <div className="col-3">
                                    <div className="h5">{trip.arriveTime}</div>
                                    <Tooltip title={trip.flyToCity}>
                                        <div className="small">{trip.flyTo}</div>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="row small">
                                {
                                    trip.stops.map((s: any, i: any) => {
                                        return (
                                            <div key={j+'-'+t+'-'+i} className="offset-4 small py-1">
                                                via {s.departFromCity} ({s.transitTime} stop)
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                );
            });

            rows.push(
                <div className="row pb-10 mb-3 journey" key={j}>
                    <div className="col-lg-9 col-md-12 shadow-sm px-3 bg-white rounded">
                        <div className="row">
                            {tripRows}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12 shadow-sm px-3 bg-white rounded centre-aligned" key={j+'_1'}>
                        <span className="my-3">
                            <HoverModal width="350px"
                                        content={
                                            <Deal key={'deals_' + j} deals={journey.PricingOptions}></Deal>
                                        }>
                                <div className="link">
                                    {journey.PricingOptions.length} deal{journey.PricingOptions.length > 1 ? 's from' : ''}
                                </div>
                            </HoverModal>
                            <div className="font-weight-bold">{journey.PricingOptions[0].pr.dp}</div>
                            <button key={'btn_' + j} type="button" className="btn btn-success font-weight-bold " onClick={() => props.onSelected(j)}>
                                Select
                            </button>
                        </span>
                    </div>
                </div>
            );
        });
    }

    return (
        <>
            <Loader key="loader" active={props.waitingState} top="200px">
                <InfiniteScroll pageStart={0}
                                loadMore={props.loadMore}
                                hasMore={props.hasMoreToLoad}
                                loader={<AnimatedLoader key="animLoader" active={props.isLoading}
                                            text="Loading Records..."
                                        ></AnimatedLoader>}
                            >
                    {rows}
                </InfiniteScroll>
            </Loader>
            { !props.hasMoreToLoad && (<div className="no-more">No More Records</div>) }
            { props.noRecords && (<div className="no-more">No Records Found</div>) }
        </>
    )
}

export default SearchResult;