import { combineReducers } from 'redux';
import { searchParamsReducer } from './search-params/search-params.reducer';
import { searchResultReducer } from './search-result/search-result.reducer';
import { searchWaitingStateReducer } from './search-waiting-state/search-waiting-state.reducer';
import { filtersReducer } from './filters/filters.reducer';
import { searchReducer } from './search/search.reducer';
import { panelsReducer } from './panels/panels.reducer';

export default combineReducers({
    search: searchReducer,
    searchParams: searchParamsReducer,
    searchResult: searchResultReducer,
    searchWaitingState: searchWaitingStateReducer,
    filters: filtersReducer,
    panels: panelsReducer
});