import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Home from './home/home';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './header/header.component';
import Booking from './booking/booking';

const App: React.FC = () => {
  return (
    <div>
      <Header></Header>
      <Router>
        <div>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/booking/:id" component={Booking} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
