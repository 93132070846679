import './loader.css';
import React from 'react';

interface SearchProps {
  active: boolean;
  top?: string;
}

export const Loader: React.FC<SearchProps> = (props) => {
  
  if(props.active)
    return (
      <div className="loader">
        <div className="loader-container" style={{ alignItems: props.top ? 'normal' : '' }}>
          <div className="spinner-border loader-spinner" role="status" style={{ top: props.top }}>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        {props.children}
      </div>
    );

    return <>{props.children}</>;
}