import SearchWaitingStateActionTypes from ".";

export interface ISearchWaitingState {
    waitingState1: boolean,
    waitingState2: boolean,
    waitingState3: boolean,
    isSearching: boolean,
    firstLoad: boolean,
    isLoading: boolean,
    hasMoreToLoad: boolean,
    noRecords: boolean
}

const INITIAL_WAITING_STATE: ISearchWaitingState = {
    waitingState1: false,
    waitingState2: false,
    waitingState3: false,
    isSearching: false,
    firstLoad: false,
    isLoading: false,
    hasMoreToLoad: true,
    noRecords: false
}

export const searchWaitingStateReducer = (state = INITIAL_WAITING_STATE, action: any) => {
    switch(action.type) {
        case SearchWaitingStateActionTypes.SET_SEARCH_WAITING_STATE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}