import FiltersActions from "."

export const initFilters = () => ({
    type: FiltersActions.INITIALIZE_FILTERS,
    payload: null
});

export const setFilters = (data: any) => ({
    type: FiltersActions.SET_FILTERS,
    payload: data
});