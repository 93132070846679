const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const day = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    
export class Utility {
    static formatDateTime(datetime: Date, separator = ' ', includeTime = false, displayMonthName = false, reverse = false): string {
        const { dateStr, monthStr, yearStr, hh, mm, ampm, dayStr } = Utility.getDateTimeParts(datetime, displayMonthName);

        let retVal = '';

        if(!reverse)
            retVal =  dateStr + separator + monthStr + separator + yearStr;
        else
            retVal =  yearStr + separator + monthStr + separator + dateStr;

        if(includeTime)
            retVal += ' - ' + hh + ':' + mm + ampm;

        return retVal;
    }
    
    static getDateTimeParts(datetime: Date, displayMonthName = false): any {
        let v = datetime;
        let min = v.getMinutes();
        let mm = min > 9 ? min : '0' + min;
        let hour = v.getUTCHours();
        let hh = '' + (hour % 12);
        hh = hh.length === 2 ? hh : '0' + hh;
        if(hh === '00') hh = '12';
        let ampm = ('' + hh + mm) < '1200' ? 'am' : 'pm';

        let monthStr: string;
        
        let dateStr = v.getDate().toString();
        if(dateStr.length === 1) dateStr = '0' + dateStr;
        
        if(displayMonthName) {
            monthStr = month[v.getMonth()];
        }
        else {
            monthStr = (v.getMonth() + 1).toString();
            if(monthStr.length === 1) monthStr = '0' + monthStr;
        }

        let yearStr = v.getFullYear();

        let dayStr = day[v.getDay()];

        return { dateStr, monthStr, yearStr, hh, mm, ampm, dayStr };
    }

    static toTime(sec: number, from: string = 'ms'): string {
        let factor: number = 0;

        switch(from) {
            case 'm': factor = 1; break;
            case 's': factor = 60; break;
            case 'ms': factor = 60 * 1000; break;
        }

        let hrs = Math.floor((sec / 60 * factor) % 24);
        let mns = Math.floor((sec / factor) % 60);
  
        let hh = (hrs <= 9 ? '0' : '') + hrs;
        let mm = (mns <= 9 ? '0' : '') + mns;
        
        return hh + ":" + mm;
    }

    static toHHMM(ms: number, from: string = 'ms'): string {
        let factor: number = 0;

        switch(from) {
            case 'm': factor = 1; break;
            case 's': factor = 60; break;
            case 'ms': factor = 60 * 1000; break;
        }

        let days = Math.floor(ms / (60 * 24 * factor)); 
        let hrs = Math.floor(ms / (60 * factor) % (24 * factor));
        let mns = Math.floor((ms / factor) % 60);
  
        return (days > 0 ? days + 'd ' : '') + (hrs > 0 ? hrs + 'h ' : '') + (mns > 0 ? mns + 'm' : '');
    }

    static toMins(datetime: string): number {
        let dt = new Date(datetime);
        return (dt.getHours() * 60) + dt.getMinutes() + (dt.getSeconds() / 60)
    }

    static getTime(datetime: string) {
        return datetime && datetime.length > 12 ? datetime.substr(11, 5) : '';
    }

    static formatCurrency(amount: number, currency: string): any {
        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: currency,
                            minimumFractionDigits: 0
                        }).format(amount);
    }

    static formatDateTimeDay(datetime: Date): string {
        const { dateStr, monthStr, yearStr, hh, mm, ampm, dayStr } = Utility.getDateTimeParts(datetime, true);

        let retVal = '';

        retVal += ' - ' + hh + ':' + mm + ampm;

        return hh + ':' + mm + ' (' + dayStr + ', ' + dateStr + ' ' + monthStr + ' ' + yearStr + ')';

        //if(includeDay)
        //return retVal;
    }

    static getCurrentDate(addDays: number): Date {
        let date = new Date();
        date.setDate(date.getDate() + addDays);
        return date;
    }
}