import PanelsActionTypes from '.';

export interface IPanels {
    isPanel1Expanded: boolean,
    isPanel2Expanded: boolean,
    isPanel3Expanded: boolean,
    isPanel4Expanded: boolean,
    isInitialized: boolean,
    verticalScrollPos: number
};

export const INITIAL_MODES_TRUE = {
    isPanel1Expanded: true,
    isPanel2Expanded: true,
    isPanel3Expanded: true,
    isPanel4Expanded: true,
};

export const INITIAL_MODES_FALSE = {
    isPanel1Expanded: false,
    isPanel2Expanded: false,
    isPanel3Expanded: false,
    isPanel4Expanded: false
};

export const panelsReducer = (state = INITIAL_MODES_TRUE, action: any) => {
    switch(action.type) {
        case PanelsActionTypes.SET_PANEL_MODES:
            return {
                ...state,
                ...action.payload,
                isInitialized: true
            };
        case PanelsActionTypes.SET_VERTICAL_SCROL_POS:
            return {
                ...state,
                verticalScrollPos: action.payload
            }
        default:
            return state;
    }
};