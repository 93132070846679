import SearchParamsActionTypes from '.';
import { Utility } from '../../utility';

export interface ISearchParams {
    currency: string,
    bookingToken: string,
    price0: number,
    price1: number,
    price2: number,
    departureAirport: string,
    departureAirportCode: string,
    departureAirports: any[],
    departureDate: string,
    arrivalAirport: string,
    arrivalAirportCode: string,
    arrivalAirports: any[],
    returnDate: string,
    adults: number,
    cabinClasses: [],
    cabinClass: string,
    children: number,
    infants: number,
    country: string,
    locale: string,
}

const INITIAL_PARAMS: ISearchParams = {
    currency: 'AUD',
    bookingToken: '',
    price0: 0,
    price1: 0,
    price2: 0,
    departureAirportCode: 'MEL',
    departureAirport: 'Tullamarine Airport, Melbourne (MEL)',
    departureAirports: [],
    departureDate: Utility.formatDateTime(Utility.getCurrentDate(3), '-', false, false, true),
    arrivalAirportCode: 'BNE',
    arrivalAirport: 'Brisbane Intl Airport, Brisbane (BNE)',
    arrivalAirports: [],
    returnDate: Utility.formatDateTime(Utility.getCurrentDate(10), '-', false, false, true),
    cabinClass: 'economy',
    adults: 1,
    cabinClasses: [],
    children: 0,
    infants: 0,
    country: "AU",
    locale: "en-US",
}

export const searchParamsReducer = (state = INITIAL_PARAMS, action: any) => {
    switch(action.type) {
        case SearchParamsActionTypes.INITIALIZE_PARAMS:
            return {
                ...INITIAL_PARAMS
            };
        case SearchParamsActionTypes.SET_SEARCH_PARAMS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}